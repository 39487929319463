<template>
    <div
        v-if="mailchimp?.url"
        class="the-newsletter-signup"
    >
        <MailchimpSubscribe
            v-if="!isSuccess"
            :url="mailchimp.url"
            :user-id="mailchimp.userId"
            :audience-id="mailchimp.audienceId"
            @error="onError"
            @success="onSuccess"
        >
            <template #default="{ subscribe, setValues, loading }">
                <Form
                    v-slot="{ handleSubmit, errors, meta }"
                    as=""
                >
                    <form
                        novalidate
                        @submit.prevent="() => { handleSubmit(subscribe); onSubmit(!meta.valid) }"
                    >
                        <legend class="the-newsletter-signup__title">{{ t('newsletter.title') }}</legend>
                        <div
                            v-if="!meta.valid || hasError"
                            ref="$errors"
                            class="the-newsletter-signup__validation-list"
                            tabindex="-1"
                        >
                            <a
                                v-if="errors?.[t('newsletter.email_input')]"
                                href="#emailAddress"
                                class="the-newsletter-signup__validation-error"
                                role="alert"
                            >
                                <BaseIcon icon="alert" />{{ errors?.[t('newsletter.email_input')] }}
                            </a>

                            <div
                                v-if="hasError"
                                class="the-newsletter-signup__validation-error"
                                role="alert"
                            >
                                <BaseIcon icon="alert" />{{ t('newsletter.has_error') }}
                            </div>
                        </div>

                        <div class="the-newsletter-signup__fields">
                            <div class="the-newsletter-signup__input-group">
                                <Field
                                    v-model="emailAddress"
                                    as=""
                                    rules="required|email"
                                    :name="t('newsletter.email_input')"
                                    class="the-newsletter-signup__validation-provider"
                                >
                                    <label
                                        for="emailAddress"
                                        class="sr-only"
                                    >
                                        {{ t('newsletter.email_field') }}
                                    </label>

                                    <input
                                        id="emailAddress"
                                        v-model="emailAddress"
                                        class="the-newsletter-signup__email"
                                        required
                                        type="email"
                                        autocomplete="email"
                                        :placeholder="t('newsletter.placeholder')"
                                    >
                                </Field>

                                <button
                                    :disabled="loading"
                                    class="button"
                                    type="submit"
                                    @click="emailAddress ? setValues(emailAddress) : null"
                                >
                                    {{ t('newsletter.button') }}
                                </button>
                            </div>
                        </div>
                    </form>
                </Form>
            </template>
        </MailchimpSubscribe>

        <div v-if="isSuccess">
            <h2 class="the-newsletter-signup__title">{{ t('newsletter.title') }}</h2>
            <p
                class="the-newsletter-signup__validation-success"
                role="alert"
            >
                {{ t('newsletter.is_success') }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { Form, Field, defineRule, configure } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';

import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';

defineRule('required', required);
defineRule('email', email);

configure({
    generateMessage: localize({
        nl,
        en
    })
});

const { locale, localeProperties, t } = useI18n();

watchEffect( () => {
    setLocale(locale.value);
});

const config = useRuntimeConfig();
const route = useRoute();

//template-refs
const $errors = ref(null);

const emailAddress = ref('');
const hasError = ref(false);
const isSuccess = ref(false);
const errorMessage = ref(null);

const mailchimp = computed(() => {
    const l = localeProperties.value?.code ?? 'nl';

    return {
        url: config.public.mailchimp[l].url,
        userId: config.public.mailchimp[l].userId,
        audienceId: config.public.mailchimp[l].audienceId,
    };
});

const reset = () => {
    emailAddress.value = '';
    isSuccess.value = false;
    hasError.value = false;
};
const onSuccess = () => {
    isSuccess.value = true;
    hasError.value = false;
};

const onError = (e) => {
    errorMessage.value = e;
    $errors.value.focus();
    hasError.value = true;
};

const onSubmit = (invalid) => {
    if (invalid) {
        $errors.value.focus();
    }
};

watch(route, () => {
    reset();
});
</script>

<style lang="less" src="./TheNewsletterSignup.less" />
