<template>
    <div class="menu-socials">
        <a
            href="https://www.facebook.com/filmfonds"
            target="_blank"
        >
            <div class="sr-only">Filmfonds - Facebook</div>
            <BaseIcon icon="social-facebook" />
        </a>
        <a
            href="https://www.instagram.com/nederlandsfilmfonds"
            target="_blank"
        >
            <div class="sr-only">Filmfonds - Instagram</div>
            <BaseIcon icon="social-instagram" />
        </a>
        <a
            href="https://nl.linkedin.com/company/nlfilmfonds"
            target="_blank"
        >
            <div class="sr-only">Filmfonds - LinkedIn</div>
            <BaseIcon icon="social-linkedin" />
        </a>
    </div>
</template>

<style lang="less" src="./MenuSocials.less" />
