<template>
    <Transition
        name="the-overlay"
        @before-enter="onEnter"
        @before-leave="onBeforeLeave"
    >
        <div
            v-show="menuOpen"
            class="the-header-overlay"
        >
            <button
                class="the-header-overlay__backdrop"
                aria-hidden="true"
                tabindex="-1"
                @click="closeMenu"
            />
            <div
                class="the-header-overlay__inner"
            >
                <slot />
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { onKeyDown } from '@vueuse/core';

const contentVisible = ref(false);
const mainStore = useMainStore();
const { menuOpen } = storeToRefs(mainStore);

const onEnter = () => contentVisible.value = true;
const onBeforeLeave = () => contentVisible.value = false;

onKeyDown('Escape', () => {
    closeMenu();
});

function closeMenu() {
    mainStore.toggleMenuOpen(false);
}
</script>

<style lang="less" src="./TheHeaderOverlay.less" />
