<script>
import jsonp from 'jsonp';
import queryString from 'query-string';

export default {
    props: {
        url: {
            required: true,
            type: String
        },

        userId: {
            required: true,
            type: String
        },

        audienceId: {
            required: true,
            type: String
        }
    },

    emits: ['error', 'success'],

    data() {
        return {
            emailAddress: null,
            success: false,
            error: null,
            loading: false
        };
    },

    computed: {
        data() {
            return queryString.stringify({
                u: this.userId,
                id: this.audienceId,
                EMAIL: this.emailAddress,
            });
        }
    },

    methods: {
        setValues(emailAddress = '') {
            this.emailAddress = emailAddress.trim();
        },

        subscribe() {
            if (this.emailAddress === null || this.loading) {
                return;
            }

            this.success = false;
            this.error = null;
            this.loading = true;

            const url = `${this.url}?${this.data}`;

            jsonp(
                url,
                {
                    param: 'c'
                },
                this.onResponse
            );
        },

        onResponse(error, data) {
            this.loading = false;

            if (error) {
                this.error = error;
            }

            if (data && data.result === 'error') {
                this.error = this.formatErrorMessage(data.msg);
            }

            if (this.error) {
                this.$emit('error', this.error);
            } else {
                this.success = true;
                this.emailAddress = null;
                this.$emit('success');
            }
        },

        formatErrorMessage(message) {
            return message.replace('0 - ', '');
        }
    },

    render() {
        return this.$slots.default({
            subscribe: this.subscribe,
            setValues: this.setValues,
            error: this.error,
            success: this.success,
            loading: this.loading
        });
    }
};
</script>
