<template>
    <header class="the-header">
        <TheHeaderBar />
        <div ref="focusTrap">
            <TheHeaderOverlay>
                <TheMenu>
                    <template #actions="{ isNl }">
                        <button
                            class="the-header__menu-close button button--label"
                            @click="closeMenu"
                        >
                            <div class="sr-only">{{ t('menu.close-menu-sr', isNl ? 'nl' : 'en') }}</div>
                            <span aria-hidden="true">{{ t('menu.close-menu', isNl ? 'nl' : 'en') }}</span>
                            <BaseIcon icon="cancel" />
                        </button>
                    </template>
                </TheMenu>
            </TheHeaderOverlay>
        </div>
    </header>
</template>

<script setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

const focusTrap = ref();
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(focusTrap);

const mainStore = useMainStore();
const { menuOpen } = storeToRefs(mainStore);

const route = useRoute();

const { t } = useI18n();

const closeMenu = () => {
    mainStore.toggleMenuOpen(false);
};

watch(menuOpen, async(boolean) => {
    if (boolean) {
        document.body.classList.add('menu-open');
        await nextTick();
        activateFocusTrap();
    } else if (document.body.classList.contains('menu-open')) {
        document.body.classList.remove('menu-open');
        await nextTick();
        deactivateFocusTrap();
    }
});

watch(route, () => {
    closeMenu();
});
</script>

<style lang="less" src="./TheHeader.less" />
