<template>
    <div>
        <div
            ref="cursor"
            class="custom-cursor"
        >
            <slot />
        </div>

        <template v-if="trailingCursors.length">
            <div
                v-for="(cur, index) in trailingCursors"
                :key="index"
                class="custom-cursor-trail"
            >
                <div
                    ref="trailers"
                    :class="cur.class"
                />
            </div>
        </template>
    </div>
</template>

<script setup>
import { gsap } from 'gsap';
import { throttle } from 'lodash-es';

const props = defineProps({
    throttleDelay: {
        type: Number,
        default: 500
    },
    hoverableElements: {
        type: Array,
        default: () => [
            {
                class: 'custom-cursor--hovering',
                elements: 'a, button'
            }
        ]
    },
    trailingCursors: {
        type: Array,
        default: () => []
    },
    mutationObserverOptions: {
        type: Object,
        default: () => {
            return {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: false,
                attributeFilter: ['open']
            };
        }
    }
});

const cursor = ref(null);
const trailers = ref(null);

const { hoverableElements, mutationObserverOptions, throttleDelay, trailingCursors } = toRefs(props);

const hoverables = ref([]);
const mutationObserver = ref(null);

const selectHoverables = () =>  {
    hoverableElements.value?.forEach((select) => {
        hoverables.value = document.querySelectorAll(select.elements);

        hoverables.value.forEach((hoverable) => {
            hoverable.addEventListener('mouseover', () => {
                document.body.classList.add(select.class);
            });
            hoverable.addEventListener('mouseout', () => {
                document.body.classList.remove(select.class);
            });
        });
    });
};

const throttledSelectHoverables = throttle(selectHoverables, throttleDelay.value);

const InitCustomCursor = () => {
    selectHoverables();

    window.addEventListener('mousemove', updateCursor);
};

const observeHTML = () => {
    mutationObserver.value = new MutationObserver(() => {
        throttledSelectHoverables();
    });

    mutationObserver.value.observe(document, mutationObserverOptions.value);
};

const updateCursor = (e) => {
    gsap.to(cursor.value, {
        duration: 0,
        top: e.clientY,
        left: e.clientX
    });

    trailingCursors.value?.forEach((c, index) => {
        gsap.to(trailers.value?.[index], Object.assign({
            top: e.clientY,
            left: e.clientX
        }, c?.gsap_options));
    });
};

onMounted(() => {
    InitCustomCursor();
    observeHTML();
});

onUnmounted(() => {
    hoverableElements.value?.forEach((select) => {
        hoverables.value = document.querySelectorAll(select.elements);

        hoverables.value.forEach((hoverable) => {
            hoverable.removeEventListener('mouseover', () => {
                document.body.classList.add(select.class);
            });

            hoverable.removeEventListener('mouseout', () => {
                document.body.classList.remove(select.class);
            });
        });
    });

    window.removeEventListener('mousemove', updateCursor);
});
</script>

<style lang="less" src="./CustomCursor.less" />
