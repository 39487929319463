<template>
    <NuxtLink
        :to="searchPath"
        class="menu-search-bar"
        @click="mainStore.toggleMenuOpen(false)"
    >
        <slot />
        <BaseIcon icon="search" />
    </NuxtLink>
</template>

<script setup>
const mainStore = useMainStore();

defineProps({
    searchPath: {
        type: String,
        required: true
    }
});
</script>

<style lang="less" src="./MenuSearchBar.less" />
