<template>
    <footer class="the-footer">
        <div>
            <TheMenu>
                <template #skiplink>
                    <a
                        href="#main"
                        class="button--gesture"
                    >
                        <div />
                        <div class="sr-only">
                            {{ t('skiplinks.content') }}
                        </div>

                        <BaseIcon icon="arrow-up-long" />
                    </a>
                </template>
            </TheMenu>
        </div>
    </footer>
</template>

<script setup>
const { t } = useI18n();
</script>

<style lang="less" src="./TheFooter.less" />
