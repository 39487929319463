<template>
    <div
        class="the-header-bar"
        :class="isVisible ? 'the-header-bar--visible' : ''"
        :inert="isHomeRoute"
    >
        <div>
            <NuxtLink
                :to="localePath('/')"
                class="the-header-bar__logo"
            >
                <img
                    src="~/assets/img/logos/logo-filmfonds-compact.png"
                    alt=""
                    height="16"
                    width="16"
                    loading="eager"
                    fetchPriority="high"
                >

                <span class="visible-mobile">Home<div class="sr-only"> - Nederlands Filmfonds</div></span>
                <span class="visible-desktop"><div class="sr-only">Home - </div>Nederlands Filmfonds</span>
            </NuxtLink>

            <CallToActions class="the-header-bar__actions">
                <AnimationSwitch />
                <MenuButton />
            </CallToActions>
        </div>
    </div>
</template>

<script setup>
const SCROLL_OFFSET = 400;
const HOME_ROUTES = ['index___nl', 'index___en'];

const { y } = useScroll(window);

const localePath = useLocalePath();
const route = useRoute();

const pastScrollOffset = computed(() => y.value > SCROLL_OFFSET);
const isHomeRoute = computed(() => HOME_ROUTES.includes(route.name));
const isVisible = computed(() => {
    if (!isHomeRoute.value) {
        return true;
    }

    return pastScrollOffset.value;
});
</script>

<style lang="less" src="./TheHeaderBar.less" />
