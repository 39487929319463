<template>
    <div>
        <EntryLink
            v-for="(cta, index) in callToActions"
            :key="index"
            class="call-to-action"
            :class="{ 'call-to-action--highlighted': cta?.highlighted }"
            :link="cta?.link?.[0]"
        />

        <slot />
    </div>
</template>

<script setup>
import getCallToActions from '~/graphql/queries/getCallToActions.graphql';

const { variables: defaultVariables }  = useCraftGraphql();

const variables = computed(() => {
    return {
        ...defaultVariables,
    };
});

const response = await useLazyAsyncQuery({
    query: getCallToActions,
    variables
});

const callToActions = computed(() => response?.data?.value?.entry?.callToAction ?? []);
</script>

<style lang="less" src="./CallToActions.less" />
