<template>
    <nav
        class="the-menu"
        :lang="isNl ? 'nl' : 'en'"
        :class="{ 'the-menu--loading': pending }"
    >
        <div class="the-menu__controls visible-mobile">
            <slot name="skiplink" />
            <div class="the-menu__header">
                <NuxtLink
                    :to="homePath"
                    class="the-menu__logo"
                >
                    <img
                        src="~/assets/img/logos/filmfonds.svg"
                        alt="Filmfonds - home"
                        width="100"
                        height="55"
                    >
                </NuxtLink>
                <MenuSearchBar :search-path="searchPath">{{ t('search.search', isNl ? 'nl' : 'en') }}</MenuSearchBar>
            </div>
            <div class="the-menu__actions">
                <LanguageSwitch
                    :is-nl="isNl"
                    @toggle="switchLanguage"
                />
                <a
                    href="https://aanvragen.filmfonds.nl/"
                    class="the-menu__login"
                >
                    {{ t('menu.login') }}
                </a>

                <slot
                    name="actions"
                    :is-nl="isNl"
                />
            </div>
        </div>
        <div class="the-menu__controls visible-desktop">
            <slot name="skiplink" />
            <div class="the-menu__header">
                <NuxtLink
                    :to="homePath"
                    class="the-menu__logo"
                >
                    <img
                        src="~/assets/img/logos/filmfonds.svg"
                        alt="Filmfonds - home"
                        width="132"
                        height="73"
                    >
                </NuxtLink>
            </div>
            <div class="the-menu__actions">
                <LanguageSwitch
                    :is-nl="isNl"
                    @toggle="switchLanguage"
                />
                <a
                    href="https://aanvragen.filmfonds.nl/"
                    class="the-menu__login"
                >
                    {{ t('menu.login') }}
                </a>
                <MenuSearchBar :search-path="searchPath">{{ t('search.search', isNl ? 'nl' : 'en') }}</MenuSearchBar>
                <slot
                    name="actions"
                    :is-nl="isNl"
                />
            </div>
        </div>
        <div
            class="the-menu__lists"
            aria-live="polite"
        >
            <MenuList
                :title="menu1Title"
                :items="menu1"
                :locale="currentSite.code"
            />
            <MenuList
                :title="menu2Title"
                :items="menu2"
                :locale="currentSite.code"
            />
            <MenuList
                :title="menu3Title"
                :items="menu3"
                :locale="currentSite.code"
            />
            <MenuList
                :title="menu4Title"
                :items="menu4"
                :locale="currentSite.code"
            />
        </div>
        <div class="the-menu__footer">
            <MenuSocials />
            <p
                v-if="!isNl"
                class="the-menu__infoline"
            >
                Please note that only a selection of the content is available in English
            </p>
        </div>
    </nav>
</template>

<script setup>
import { cloneDeep } from 'lodash-es';
import getMainMenu from '~/graphql/queries/getMainMenu.graphql';

const NL_LANG_CODE = 'nl';

const { locales, localeProperties, locale, t } = useI18n();

const localePath = useLocalePath();

const primaryActive = ref(true);

watch(locale, () => {
    primaryActive.value = true;
});

const currentSite = computed(() => {
    if (primaryActive.value) {
        return localeProperties.value;
    }

    return locales.value.find((l) => {
        return l.code !== localeProperties.value.craftSiteHandle;
    });
});
const siteHandle = computed(() => currentSite.value.craftSiteHandle);
const variables = computed(() => {
    return {
        site: siteHandle.value,
        uri: null //unset uri from defaultVariables
    };
});

const { data, pending } = await useLazyAsyncQuery({
    query: getMainMenu,
    variables,
});

const translateLink = (link) => {
    if (link?.link?.[0]?.entry?.[0]?.uri === null) {
        return link;
    }

    const newLink = cloneDeep(link);
    newLink.link[0].entry[0].uri = localePath(`/${link?.link?.[0]?.entry?.[0]?.uri}`, currentSite.value.code);
    return newLink;
};

const menuData = computed (() => data.value?.globalSets?.[0] ?? null);
const menu1Title = computed(() => menuData.value?.title1 ?? null);
const menu1 = computed(() => menuData.value?.entryLinks?.map(translateLink) ?? []);
const menu2Title = computed(() => menuData.value?.title2 ?? null);
const menu2 = computed(() => menuData.value?.entryLinks2?.map(translateLink) ?? []);
const menu3Title = computed(() => menuData.value?.title3 ?? null);
const menu3 = computed(() => menuData.value?.entryLinks3?.map(translateLink) ?? []);
const menu4Title = computed(() => menuData.value?.title4 ?? null);
const menu4 = computed(() => menuData.value?.entryLinks4?.map(translateLink) ?? []);

const homePath = computed(() => localePath('/', currentSite.value.code));
const isNl = computed(() => currentSite.value.code === NL_LANG_CODE);
const searchPath = computed(() => localePath({ name: 'zoek' }, currentSite.value.code));

const switchLanguage = () => {
    primaryActive.value = !primaryActive.value;
    locale.value = isNl.value ? 'nl' : 'en';
};
</script>

<style lang="less" src="./TheMenu.less" />
