<template>
    <button
        class="language-switch"
        @click="emit('toggle')"
    >
        <div class="sr-only">
            <span
                v-if="isNl"
                lang="nl-NL"
            >
                Engels menu bekijken
            </span>
            <span
                v-else
                lang="en"
            >
                View dutch menu
            </span>
        </div>

        <span
            class="language-switch__item"
            :class="{ 'language-switch__item--active': isNl }"
            aria-hidden="true"
        >
            NL
        </span>

        <BaseIcon icon="arrow-right" />

        <span
            class="language-switch__item"
            :class="{ 'language-switch__item--active': !isNl }"
            aria-hidden="true"
        >
            ENG
        </span>
    </button>
</template>

<script setup>
defineProps({
    isNl: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['toggle']);
</script>

<style lang="less" src="./LanguageSwitch.less" />
